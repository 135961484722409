<script>
import Swal from 'sweetalert2'

import Layout from "../../../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import Helper from '@/helpers/helper';

import { ref } from "vue";


/**
 * Dashboard Component
 */
export default {
    setup() {
        const doesAddOpen = ref(false);
        const doesEditOpen = ref(false);
        const doesDeleteOpen = ref(false);

        return {
          doesAddOpen,
          doesEditOpen,
          doesDeleteOpen,
        };
    },
    page: {
    title: "Conventions",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "Conventions",
      items: [
        {
          text: "Menu",
          href: "/",
        },
        {
          text: "Conventions",
          active: true,
        },
      ],
      errors: [],
      forests: [],
      convention: {},
      conventions: [],
      convention_id: "",
      forest_id: "",
      loading: false,
      message: "",
      status: 201,
      STORAGE_URL: Helper.STORAGE_URL,
    };
  },
  methods: {
    loadList() {
      fetch(Helper.route('conventions'), Helper.requestOptions())
      .then(response => {
          Helper.tokenChecker(response.status)
          return response.json()
      })
      .then(data => {
        console.log(data.data);
        this.conventions = data.data
      })
    },
    rejectItem() {
      this.loading = true
      this.errors = []
      let formData = new FormData()
      formData.append('status', -1)
      formData.append('message', this.message)
      formData.append('_method', "PUT")
      fetch(Helper.route('conventions/'+this.convention.id), Helper.requestOptions("POST", formData))
      .then(response => {
          Helper.tokenChecker(response.status)
          this.status = response.status
          return response.json()
      })
      .then(data => {
        console.log(this.status);
        console.log(data);
        this.loading = false
        if (this.status == 200) {
          this.message = ""
          this.doesEditOpen = false
          this.conventions = this.conventions.map(i => i.id !== data.data.id ? i : data.data)
          Swal.fire("Convention modifier !", "La Convention a été réjétée avec succès.", "success");
        } else {      
            this.errors.message = Helper.getError(data.data, "message")
        }
      })
      .catch(error => {
        console.log(error);
        this.loading = false
      })
    },
    approveIt() {
      this.loading = true
      let formData = new FormData()
      formData.append('status', 1)
      formData.append('file', document.getElementById("file").files[0])
      formData.append('_method', "PUT")
      fetch(Helper.route('conventions/'+this.convention.id), Helper.requestOptions("POST", formData))
      .then(response => {
          Helper.tokenChecker(response.status)
          this.status = response.status
          return response.json()
      })
      .then(data => {
        console.log(this.status);
        console.log(data);
        this.loading = false
        if (this.status == 200) {
          this.message = ""
          this.doesDeleteOpen = false
          this.conventions = this.conventions.map(i => i.id !== data.data.id ? i : data.data)
          Swal.fire("Convention modifier !", "Le Convention a été approuvée avec succès.", "success");
        } else {      
            this.errors.message = Helper.getError(data.data, "message")
        }
      })
      .catch(error => {
        console.log(error);
        this.loading = false
      })
    },
    initItem(convention, action="edit") {
        this.convention = convention
        this.initData(convention)
        if (action == "approve") {
          this.doesDeleteOpen = true
        } else if(action == "view") {
          this.doesAddOpen = true
        } else {
          this.doesEditOpen = true
        }
    },
    initData(item = null) {
      this.convention_id = item != null ? item.id : ""
    }
  },
  mounted() {
    this.loadList()
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Listes des conventions</h4>
            <p class="card-title-desc">
              Le tableau ci-dessous présente la listes des <code>conventions</code>.
            </p>

            <div class="table-responsive">
              <table class="table align-middle mb-0">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Forêt</th>
                    <th>Convention</th>
                    <th>IF Status</th>
                    <th>Direction Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(convention, i) in conventions" :key="convention.id">
                    <th scope="row">{{ i + 1 }}</th>
                    <td>
                      <router-link :to="'/entity/forests/'+convention.forest.id">{{ convention.forest.name }}</router-link>
                    </td>
                    <td>
                      <a :href="STORAGE_URL+convention.convention" v-if="convention.convention !== ''" target="_blank" class="btn btn-sm btn-success rounded-pill">
                          <i class="bx bx-download font-size-14 align-middle mx-1 text-white"></i>
                          Télécharger le Fichier
                      </a>
                    </td>
                    <td>
                      {{ (convention.convention_validation_step == 1 && convention.convention_status == 1) || convention.convention_validation_step == 2 ? "Approuvé" : ""  }}
                      {{ convention.convention_validation_step == 1 && convention.convention_status == 0 ? "En étude" : "" }}
                      {{ convention.convention_validation_step == 1 && convention.convention_status == -1 ? "Réjétée" : "" }}
                    </td>
                    <td>
                      {{ (convention.convention_validation_step == 2 && convention.convention_status == 1) ? "Approuvé" : ""  }}
                      {{ convention.convention_validation_step == 2 ? (convention.convention_status == 0 ?  "En étude" : "") : "Au niveau de IF" }}
                      {{ convention.convention_validation_step == 2 && convention.convention_status == -1 ? "Réjétée" : "" }}
                    </td>
                    <td class="hstack">
                        <template v-if="convention.convention_validation_step == 2">
                          <div>
                            <template v-if="convention.convention_status == 0">
                              <div class="hstack">
                                <b-button variant="success" @click="initItem(convention, 'approve')" class="btn-sm">
                                    Approuver
                                </b-button>
                                <b-button variant="danger" @click="initItem(convention, 'reject')" class="mx-2 btn-sm">
                                    Réjéter
                                </b-button>
                              </div>
                            </template>
                            <template v-if="convention.convention_status == -1">
                              <div>
                                <b-button variant="warning" @click="initItem(convention, 'view')" class="me-2 btn-sm">
                                  Message de rejet
                                </b-button>
                              </div>
                            </template>
                          </div>
                        </template>
                        <router-link :to="'/entity/conventions/'+convention.id">
                          <b-button class="btn-sm" variant="info">
                              Detail
                          </b-button>
                        </router-link>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>


    <!-- ADD MODAL -->
    <b-modal
        v-model="doesEditOpen"
        id="modal-center"
        size="md"
        title="Réjéter la Convention"
        title-class="font-18"
        hide-footer
        >
        <form @submit.prevent="rejectItem()">
          <div class="row">
            <div class="col-md-12 mb-3">
                <label for="">Message de rejet</label>
                <textarea  class="form-control" required v-model="message" rows="3"></textarea>
                <span class="text-danger mt-2" v-if="errors.message">{{ errors.message }}</span>
            </div>
          </div>
          <div class="mt-3" v-if="!loading">
            <input type="submit" value="Réjéter la convention" class="btn btn-danger">
          </div>
          <div class="mt-3 text-center" v-else>
            <div class="spinner-border text-primary" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        </form>
    </b-modal>


    <!-- DELETE MODAL -->
    <b-modal
        v-model="doesDeleteOpen"
        id="modal-center"
        title="Approuver la Convention"
        title-class="font-18"
        hide-footer
        >
        <form @submit.prevent="approveIt()">
          <div class="row">
            <div class="col-md-12 mb-3">
                <label for="">Convention finale</label>
                <input type="file" id="file" accept=".docx, .pdf" class="form-control" required>
                <span class="text-danger mt-2" v-if="errors.file">{{ errors.file }}</span>
            </div>
          </div>
          <div class="mt-3" v-if="!loading">
            <input type="submit" value="Approuver la convention" class="btn btn-success">
          </div>
          <div class="mt-3 text-center" v-else>
            <div class="spinner-border text-primary" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        </form>
    </b-modal>

    <!-- DELETE MODAL -->
    <b-modal
        v-model="doesAddOpen"
        id="modal-center"
        title="Message de rejet"
        title-class="font-18"
        centered
        hide-footer
        >
        <p class="mb-2 lh-lg">
            {{ convention?.convention_message }}
        </p>
    </b-modal>

  </Layout>
</template>
